export const CHANGE_MARKET = 'CHANGE_MARKET/PICKSTOCK'
export const CHANGE_RANGE_INDEX = 'CHANGE_RANGE_INDEX/PICKSTOCK'
export const CANCEL_MARKET = 'CANCEL_MARKET/PICKSTOCK'
export const SELECTED_INDICATE = 'SELECTED_INDICATE/PICKSTOCK'
export const CHOOSE_INDICATE = 'CHOOSE_INDICATE/PICKSTOCK'
export const CHOOSE_MARKET = 'CHOOSE_MARKET/PICKSTOCK'
export const UPDATE_INDICATE = 'UPDATE_INDICATE/PICKSTOCK'
export const SET_SELECTED_LIST = 'SET_SELECTED_LIST/PICKSTOCK'
export const SET_START_DATE_VALUE = 'SET_START_DATE_VALUE/PICKSTOCK'
export const SET_END_DATE_VALUE = 'SET_END_DATE_VALUE/PICKSTOCK'
export const SELECTED_STRATEGY = 'SELECTED_STRATEGY/PICKSTOCK'
export const SET_POOL_LIST = 'SET_POOL_LIST/PICKSTOCK'
export const SET_POOL_ID = 'SET_POOL_ID/PICKSTOCK'
export const SET_POOL_SELECT_CODE = 'SET_POOL_SELECT_CODE/PICKSTOCK'
export const INIT_PICKSTOCK = 'INIT_PICKSTOCK/PICKSTOCK'
export const SET_INDICATE ='SET_INDICATE/PICKSTOCK'
export const SET_INDICATE_ARR = 'SET_INDICATE_ARR/PICKSTOCK'
export const SET_MARKET_ARR = 'SET_MARKET_ARR/PICKSTOCK'
export const SET_MARKET_OBJ = 'SET_MARKET_OBJ/PICKSTOCK'
export const SET_PERIOD = 'SET_PERIOD/PICKSTOCK'


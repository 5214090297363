
import {SET_FIRST_IN} from './actionTypes'

const defaultState = {
    isFirstIn: true,

}

const reducer = (state =defaultState , action) => {
    switch (action.type) {
       
        case SET_FIRST_IN: {
            return {
                ...state, isFirstIn: action.data
            }
        } 


        default:
            break
    }
    return state
}

export default reducer
export const CHANGE_STOCK_DATA = 'TRADE/CHANGE_STOCK_DATA'  //K线数据

export const CHANGE_QUOTE_DATA = 'TRADE/CHANGE_QUOTE_DATA' //行情数据

export const SET_ACCOUNT = 'TRADE/SET_ACCOUNT'

export const CHANGE_PERIOD = "TRADE/CHANGE_PERIOD"

export const CHANGE_CODE = 'TRADE/CHANGE_CODE'

export const CHANGE_FAV = 'TRADE/CHANGE_FAV'  //自选列表

export const SET_ACCOUNT_LIST = 'TRADE/SET_ACCOUNT_LIST'

export const SET_CURRENT_ACCOUNT_ID = 'TRADE/SET_CURRENT_ACCOUNT_ID'

export const SET_MY_STRATEGY_LIST = 'TRADE/SET_MY_STRATEGY_LIST'

export const SET_STRATEGY_INDEX = 'TRADE/SET_STRATEGY_INDEX'

export const UPDATE_TRUST_DATA = 'TRADE/UPDATE_TRUST_DATA'

export const CHANGE_CHART_TYPE = 'TRADE/CHANGE_CHART_TYPE'

export const SET_CURRENT_POSITION = 'TRADE/SET_CURRENT_POSITION'